/**
 * We don't want to import model.ts, because it's quite huge and we only need a few types.
 */
import {BlobUID, BoardUID, AccountUID, Language, CardUID} from "@cling/lib.shared.model/types"
import Pbf from "pbf"

// For some reason, eslint thinks that this is already defined.
export enum SWThumbnailImageType {
    png = 1,
    webp = 2,
    avif = 3,
    jpeg = 4,
}

export class SWQueryThumbnailRequest {
    static from_b64url(s: string): SWQueryThumbnailRequest {
        const u8 = from_b64url(s)
        const pbf = new Pbf(u8)
        const o = {}
        pbf.readFields(SWQueryThumbnailRequest._from_pb_fields, o)
        return new SWQueryThumbnailRequest({...(o as any)})
    }

    static _from_pb_fields(tag: number, o: any, pbf: any) {
        switch (tag) {
            case 1: {
                o.blob_uid = pbf.readString()
                return
            }
            case 2: {
                o.max_thumbnail_width = pbf.readVarint()
                return
            }
            case 3: {
                o.max_thumbnail_height = pbf.readVarint()
                return
            }
            case 4: {
                o.image_type = pbf.readVarint()
                return
            }
        }
    }

    blob_uid: BlobUID
    max_thumbnail_width: number
    max_thumbnail_height: number
    image_type: SWThumbnailImageType

    constructor(args: {
        blob_uid: BlobUID
        max_thumbnail_width: number
        max_thumbnail_height: number
        image_type: SWThumbnailImageType
    }) {
        this.blob_uid = args.blob_uid
        this.max_thumbnail_width = args.max_thumbnail_width || 0
        this.max_thumbnail_height = args.max_thumbnail_height || 0
        this.image_type = args.image_type
    }

    to_b64url(): string {
        const pbf = new Pbf(new ArrayBuffer(16))
        pbf.writeStringField(1, this.blob_uid)
        pbf.writeVarintField(2, this.max_thumbnail_width)
        pbf.writeVarintField(3, this.max_thumbnail_height)
        pbf.writeVarintField(4, this.image_type)
        const u8 = pbf.finish()
        return to_b64url(u8)
    }
}

export class SWQueryThumbnailURLRequest {
    static from_b64url(s: string): SWQueryThumbnailURLRequest {
        const u8 = from_b64url(s)
        const pbf = new Pbf(u8)
        const o = {}
        pbf.readFields(SWQueryThumbnailURLRequest._from_pb_fields, o)
        return new SWQueryThumbnailURLRequest({...(o as any)})
    }

    static _from_pb_fields(tag: number, o: any, pbf: any) {
        switch (tag) {
            case 1: {
                o.blob_uid = pbf.readString()
                return
            }
            case 2: {
                o.max_thumbnail_width = pbf.readVarint()
                return
            }
            case 3: {
                o.max_thumbnail_height = pbf.readVarint()
                return
            }
            case 4: {
                o.redirect = pbf.readBoolean()
                return
            }
            case 5: {
                o.image_type = pbf.readVarint()
                return
            }
            case 6: {
                o.ttl_seconds = pbf.readVarint()
                return
            }
        }
    }

    blob_uid: BlobUID
    max_thumbnail_width: number
    max_thumbnail_height: number
    redirect: boolean
    image_type: SWThumbnailImageType
    ttl_seconds: number

    constructor(args: {
        blob_uid: BlobUID
        max_thumbnail_width: number
        max_thumbnail_height: number
        redirect?: boolean
        image_type?: SWThumbnailImageType
        ttl_seconds?: number
    }) {
        this.blob_uid = args.blob_uid
        this.max_thumbnail_width = args.max_thumbnail_width || 0
        this.max_thumbnail_height = args.max_thumbnail_height || 0
        this.redirect = args.redirect || false
        this.image_type = args.image_type || SWThumbnailImageType.jpeg
        this.ttl_seconds = args.ttl_seconds || 300
    }

    to_b64url(): string {
        const pbf = new Pbf(new ArrayBuffer(16))
        pbf.writeStringField(1, this.blob_uid)
        pbf.writeVarintField(2, this.max_thumbnail_width)
        pbf.writeVarintField(3, this.max_thumbnail_height)
        pbf.writeBooleanField(4, this.redirect)
        pbf.writeVarintField(5, this.image_type)
        pbf.writeVarintField(6, this.ttl_seconds)
        const u8 = pbf.finish()
        return to_b64url(u8)
    }
}

export class SWQueryThumbnailURLResponse {
    static from_b64url(s: string): SWQueryThumbnailURLResponse {
        return SWQueryThumbnailURLResponse.from_buffer(from_b64url(s))
    }

    static from_buffer(buf: ArrayBuffer): SWQueryThumbnailURLResponse {
        const pbf = new Pbf(buf)
        const o = {}
        pbf.readFields(SWQueryThumbnailURLResponse._from_pb_fields, o)
        return new SWQueryThumbnailURLResponse({...(o as any)})
    }

    static _from_pb_fields(tag: number, o: any, pbf: any) {
        switch (tag) {
            case 1: {
                o.url = pbf.readString()
                return
            }
            case 2: {
                o.resolved_blob_uid = pbf.readString()
                return
            }
            case 3: {
                o.mime_type = pbf.readString()
                return
            }
        }
    }

    url: string
    resolved_blob_uid: BlobUID
    mime_type: string

    constructor(args: {url: string; resolved_blob_uid: BlobUID; mime_type: string}) {
        this.url = args.url || ""
        this.resolved_blob_uid = args.resolved_blob_uid
        this.mime_type = args.mime_type || ""
    }
}

export class SWPushNotificationBoardChanged {
    static from_pbf(pbf: any): SWPushNotificationBoardChanged {
        const o = {changed_by_account_uids: []}
        pbf.readFields(SWPushNotificationBoardChanged._from_pb_fields, o)
        return new SWPushNotificationBoardChanged({...(o as any)})
    }

    static _from_pb_fields(tag: number, o: any, pbf: any) {
        switch (tag) {
            case 1: {
                o.board_uid = pbf.readString()
                return
            }
            case 2: {
                o.card_uid = pbf.readString()
                return
            }
            case 3: {
                o.version = pbf.readVarint()
                return
            }
            case 4: {
                o.language = pbf.readString()
                return
            }
            case 5: {
                o.title = pbf.readString()
                return
            }
            case 6: {
                o.body = pbf.readString()
                return
            }
            case 7: {
                o.image_url = pbf.readString()
                return
            }
            case 8: {
                o.is_comment = pbf.readBoolean()
                return
            }
            case 9: {
                o.changed_by_account_uids.push(pbf.readString())
                return
            }
        }
    }

    board_uid: BoardUID
    card_uid: CardUID | undefined
    version: number
    language: Language
    title: string
    body: string
    image_url: string
    is_comment: boolean
    changed_by_account_uids: Array<AccountUID>

    constructor(args: {
        board_uid: BoardUID
        card_uid?: CardUID
        version: number
        language: Language
        title: string
        body?: string
        image_url?: string
        is_comment?: boolean
        changed_by_account_uids: Array<AccountUID>
    }) {
        this.board_uid = args.board_uid
        this.card_uid = args.card_uid
        this.version = args.version || 0
        this.is_comment = args.is_comment || false
        this.language = args.language
        this.title = args.title || ""
        this.body = args.body || ""
        this.image_url = args.image_url || ""
        this.changed_by_account_uids = args.changed_by_account_uids
    }
}

export class SWPushNotification {
    static from_b64url(url: string): SWPushNotification {
        const u8 = from_b64url(url)
        const pbf = new Pbf(u8)
        const o = {}
        pbf.readFields(SWPushNotification._from_pb_fields, o)
        return new SWPushNotification({...(o as any)})
    }

    static _from_pb_fields(tag: number, o: any, pbf: any) {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (tag) {
            case 1: {
                const board_changed = new SWPushNotificationBoardChanged({
                    ...pbf.readMessage(SWPushNotificationBoardChanged._from_pb_fields, {
                        changed_by_account_uids: [],
                    }),
                })
                o.board_changed = board_changed
                return
            }
        }
    }

    board_changed: SWPushNotificationBoardChanged | undefined

    constructor(args: {board_changed?: SWPushNotificationBoardChanged}) {
        this.board_changed = args.board_changed
    }

    public get kind(): SWPushNotificationBoardChanged {
        if (!this.board_changed) {
            throw new Error("SWPushNotification is expected to have a `board_changed` property")
        }
        return this.board_changed
    }
}

function to_b64url(buf: Uint8Array): string {
    return btoa(
        Array.from(buf)
            .map((val) => {
                return String.fromCharCode(val)
            })
            .join(""),
    )
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "")
}

function from_b64url(s: string): Uint8Array {
    return new Uint8Array(
        atob(s.replace(/-/g, "+").replace(/_/g, "/"))
            .split("")
            .map((val) => {
                return val.charCodeAt(0)
            }),
    )
}
