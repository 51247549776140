declare const window: any

export function class_name(obj: any): string {
    let result: string = typeof obj
    if (result === "function") {
        return obj.name
    }
    if (result === "object") {
        if (obj === null) {
            result = "null"
        } else {
            const c = obj.constructor as any
            if (c) {
                result = c.displayName || c.name
                if (!result) {
                    // Special handling for protobuf messages ...
                    if (obj.$type) {
                        result = obj.$type.name
                    }
                }
                if (!result) {
                    try {
                        // Workaround for IE -- see https://goo.gl/IUuSNO ...
                        const a = /function\s+([^\s(]+)/.exec(c.toString())
                        result = a![1]
                    } catch {
                        result = "???"
                    }
                }
            } else {
                result = "???"
            }
        }
    }
    return result
}

/**
 * Converts `obj` to JSON just like ``JSON.stringify` does,
 * but handles circular values and errors.
 *
 * Adapted from https://github.com/moll/json-stringify-safe/blob/master/stringify.js
 */
export function safe_JSON_stringify(obj: any, replacer?: any, spaces?: number): string {
    function to_JSON(x: any) {
        const stack: any = []
        const keys: any = []

        function cycle_replacer(key: string, value: any) {
            if (stack[0] === value) {
                return "<circular ref: ~>"
            }
            return "<circular ref: ~." + keys.slice(0, stack.indexOf(value)).join(".") + ">"
        }

        function serializer(this: any, key: string, value: any) {
            if (process.env.RUNNING_IN_BROWSER) {
                if (value === window) {
                    return "<window>"
                }
            }
            if (stack.length > 0) {
                const pos = stack.indexOf(this)
                if (pos !== -1) {
                    stack.splice(pos + 1)
                    keys.splice(pos, Infinity, key)
                } else {
                    stack.push(this)
                    keys.push(key)
                }
                // Handle circular references.
                if (stack.indexOf(value) !== -1) {
                    value = cycle_replacer.call(this, key, value)
                }
            } else {
                stack.push(value)
            }
            return replacer ? replacer.call(this, key, value) : value
        }
        return JSON.stringify(x, serializer, spaces)
    }

    try {
        return to_JSON(obj)
    } catch {
        try {
            if (typeof obj === "object") {
                const o: any = {}
                for (const [k, v] of Object.entries(obj)) {
                    if (typeof v !== "function") {
                        try {
                            to_JSON(v)
                        } catch {
                            o[k] = "<Could not be converted to JSON>"
                            continue
                        }
                        o[k] = v
                    }
                }
                return to_JSON(o)
            }
        } catch {
            /* Ignored. */
        }
        return "<Could not be converted to JSON>"
    }
}

function element_to_string(elm: {tagName: string; className: string; id: string}) {
    const tag_name = elm.tagName.toLowerCase()
    let s = "<" + tag_name
    if (elm.id) {
        s += ' id="' + elm.id + '"'
    }
    if (elm.className) {
        s += ' class="' + elm.className + '"'
    }
    if ((elm as any).src) {
        s += ' src="' + (elm as any).src + '"'
    }
    if ((elm as any).href) {
        s += ' href="' + (elm as any).href + '"'
    }
    s += ">"
    return s
}

export function safe_to_string(x: any) {
    try {
        if (x.tagName) {
            return element_to_string(x)
        } else if (typeof x === "number") {
            return x.toString()
        } else if (x instanceof Error) {
            return error_to_string(x)
        } else {
            return safe_JSON_stringify(x)
        }
    } catch {
        try {
            return "" + x
        } catch (error2) {
            try {
                return "??? -- " + error2
            } catch {
                return "???"
            }
        }
    }
}

export function error_to_string(error: any) {
    let s = "" + error
    try {
        let stack: string = (error as any).stacktrace || error.stack
        if (stack) {
            stack = "" + stack
            if (error.message && stack.indexOf(error.message) >= 0) {
                s = stack
            } else {
                s = s + "\n" + stack
            }
        }
    } catch {
        // Ignored
    }
    if (s.startsWith("[object")) {
        // No error? What do we have here? Assemble a string with all properties ...
        const a = []
        for (const k in error) {
            // Note: We want *all* properties of the error (except functions),
            // therefore we don't filter with hasOwnProperty ...
            const v: any = (error as any)[k]
            if (typeof v === "function") {
                continue
            }
            a.push(`${k}: ${safe_to_string(v)}`)
        }
        s = a.join("\n\t")
    }
    return s
}
